<template>
   <main>
      <section class="login-area py-md-5 py-3">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2">
                  <div class="basic-login pt-50 pb-50">
                     <h3 class="text-center mb-60">Registrar</h3>
                     <form action="javascript:;">
                        <label for="username">Nome de usuário <span>**</span></label>
                        <input id="username" type="text" placeholder="Insira o nome de usuário..." v-model="dados.username" />

                        <label for="nome-id">Nome completo <span>**</span></label>
                        <input id="nome-id" type="text" placeholder="Insira o nome completo..." v-model="dados.nome" />

                        <label for="cpf-id">CPF <span>**</span></label>
                        <input id="cpf-id" type="text" placeholder="Insira o CPF..." v-model="dados.cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" />

                        <label for="pass">Senha <span>**</span></label>
                        <input id="pass" type="password" placeholder="Insira a senha..." v-model="dados.senha" />

                        <div class="mt-10"></div>

                        <button class="t-y-btn w-100" @click="registrar">Registrar</button>
                        <div class="or-divide"><span>ou</span></div>
                        <router-link to="/login" class="t-y-btn t-y-btn-grey w-100">Entrar</router-link>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import router from '@/router'
import { mapState } from 'vuex'

export default {
	name: 'Registro',
   data: function() {
		return {
			dados: {'username': '', 'nome': '', 'cpf': '', 'senha': ''}
		}
	},
   computed: {
		... mapState({
         urlRest: state => state.urlRest
		})
	},
   methods: {
      registrar : function () {
         if (this.dados.username.length < 4) {
            this.$toast.fire({
               icon: 'error',
               title: 'Digite ao menos 4 caracteres no nome de usuário!'
            });

            return
         } else if (this.dados.nome.length < 10) {
            this.$toast.fire({
               icon: 'error',
               title: 'Digite ao menos 10 caracteres no nome completo!'
            });

            return
         } else if (this.dados.cpf.length < 14) {
            this.$toast.fire({
               icon: 'error',
               title: 'CPF inválido!'
            });

            return
         } else if (this.dados.senha.length < 4) {
            this.$toast.fire({
               icon: 'error',
               title: 'Digite ao menos 4 caracteres na senha!'
            });

            return
         }

         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'user/register',
            params: {
               nomeCompleto: this.dados.nome,
               login: this.dados.username,
               cpf: this.dados.cpf,
               senha: this.dados.senha
            }
         }).then(function () {
            router.push('/login').catch(function(){})

            ref.$toast.fire({
               icon: 'success',
               title: 'Registrado com sucesso!'
            });
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 400) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Nome de usuário já existente!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   }
}

</script>